/**
 * org store
 * @description
 * 用户所属机构
 */
/*
 * arrayNodes节点集合 attChildren下级名称默认children
 * 将多级数组对象变为同一级
 */
import treeFilter from 'operation-tree-node/lib/cjs/treeFilter.js';
import { localDataUser } from '@/utils/local-data';

// function getAllNodes(arrayNodes, attChildren) {
//     console.log("arrayNodes, attChildren", arrayNodes, attChildren)
//     if (arrayNodes === null) {
//         return [];
//     }
//     attChildren = attChildren === null ? 'childrens' : attChildren;
//     var result = [];
//     for (var ii = 0; ii < arrayNodes.length; ii++) {
//         var pNode = arrayNodes[ii];
//         result.push(pNode);
//         if (pNode[attChildren]) {
//             var arrayTempNodes = this.getAllNodes(pNode[attChildren], attChildren);
//             result = result.concat(arrayTempNodes);
//         }
//     }
//     return result;
// }

function getAllNodes(arrayNodes, attChildren) {
  if (!arrayNodes) {
    return [];
  }
  attChildren = !attChildren ? 'childrens' : attChildren;
  var result = [];
  for (var ii = 0; ii < arrayNodes.length; ii++) {
    var pNode = arrayNodes[ii];
    result.push(pNode);
    if (pNode[attChildren]) {
      var arrayTempNodes = getAllNodes(pNode[attChildren], attChildren);
      result = result.concat(arrayTempNodes);
    }
  }
  return result;
}

function filterOrg(org) {
  if (org) {
    const orgList = org.filter((e) => e.code === localDataUser.get().areaCode.toString());
    const result = treeFilter(
      orgList,
      (node) => {
        return !node.code.includes('-') && node.status !== 0;
      },
      {
        children: 'childrens'
      }
    );
    return result;
  }
  return [];
}
export default {
  state: {
    org: [],
    allOrgList: [],
    filterOrg: []
  },
  getters: {
    getOrg: function(state) {
      return state.org;
    },
    filterOrg: function(state) {
      return state.filterOrg;
    },
    getOrgNameByCode: function(state) {
      return function(code) {
        if (!code) return;
        const object = state.allOrgList.find(function(item) {
          return item.code === code;
        });
        if (object && object.name) {
          return object.name;
        } else {
          return;
        }
      };
    },
    getCodeByOrgName: function(state) {
      return function(name) {
        if (!name) return;
        const object = state.allOrgList.find(function(item) {
          return item.name === name;
        });
        if (object && object.code) {
          return object.code;
        } else {
          return;
        }
      };
    }
  },
  mutations: {
    setOrg(state, org) {
      state.org = org;
      state.filterOrg = filterOrg(org);
      state.allOrgList = getAllNodes(state.org);
    }
  },
  actions: {
    setOrg({ commit }, org) {
      commit('setOrg', org);
    }
  }
};
