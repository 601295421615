/**
 * 路由配置
 */
import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/index';
import treeEach from 'operation-tree-node/lib/cjs/treeEach.js';

// 主容器组件
import Layout from '../layout';

//首页路由
import Dashboard from 'iebdc/views/new-dashboard';

// 个人注册组件
import SignIn from '../views/sign-in';

// 新增 个人注册组件 前置步骤
import PreSignIn from '../views/pre-sign-in';

// 新增 个人注册组件 前置步骤
import ChangePassword from '../views/change-password';
import { crypto } from '@manager/utils/crypto';
// 主区子路由
// import dashboardRoute from './modules/dashboard'; // 主界面
import dashboardRoute from './modules/new-dashboard';
import policyLawRoute from './modules/policy-law'; // 政策法规
import batchProcessing from './modules/batch-processing'; // 政策法规
import guidanceRoute from './modules/guidance'; // 办事指南
import fileDownloadRoute from './modules/file-download'; // 资料下载
import messageRoute from './modules/message'; // 留言板
import publicAnnouncementRoute from './modules/public-announcement'; // 公示公告
import userCenter from './modules/user-center'; // 个人中心
import exampleRoute from './modules/example';
// import apply from './modules/apply'; // 我要申请
import myApplicationRoute from './modules/my-application'; // 我的申请
// import serviceAppointment from './modules/service-appointment'; // 我要预约
import myAppointmentRoute from './modules/my-appointment'; // 我的预约
// import visitionService from './modules/visiting-service'; // 上门服务
// import myVisitRoute from './modules/my-visit'; // 我的上门
// import progressQuery from './modules/progress-query'; // 进度查询
// import certificateVerification from './modules/certificate-verification'; // 证书核实
// import quanSZMQuery from './modules/quanSZM-query'; // 权属证明查询
import myQueryRoute from './modules/my-query'; // 我的查询
import express from './modules/express'; // 我的邮寄
import aboutRoute from './modules/about'; // 关于我们
import statementRoute from './modules/statement'; // 本站声明
import newmanGuideRoute from './modules/newman-guide'; // 新手向导
import connectRoute from './modules/connect'; // 联系我们
import callRoute from './modules/call'; // 联系我们
// import electronicLicenseRoute from './modules/electronic-license'; //电子证照
// import onlinePay from './modules/online-pay'; //我的缴费
// import gpyTest from './modules/gpy-test'; //gpyTest
// import checkInRoute from './modules/check-in'; // 我要申请
import infoSearchRoute from './modules/info-search'; // 我要查询办理
// import appointmentRoute from './modules/appointment'; // 网上预约
import bswd from './modules/bswd'; //办事网点
// import xxfw from './modules/xxfw'; //信息服务
import wybl from './modules/wybl'; //我要办理

import { localDataUser, localDataPermission } from '@iebdc/utils/local-data';
import { routerPermission } from '@iebdc/utils/utils';
import unifiedService from '@iebdc/api/unified-service.js';
import { checkTokenHandle } from './login-hn';

import { getOrg, getArea } from '@manager/api/user';
import { decryptByDES } from '@iebdc/utils/crypto';

// 子模块的路由注册
export const subRoutes = [
  dashboardRoute, //首页
  // checkInRoute, //我要申请
  wybl, //我要办理
  infoSearchRoute, //我要查询
  guidanceRoute, // 办事指南
  batchProcessing, //批量办理
  policyLawRoute, //政策法规
  publicAnnouncementRoute, //公示公告
  fileDownloadRoute, //资料下载
  // appointmentRoute, //网上预约
  // onlinePay, //我的缴费
  bswd, //办事网点
  messageRoute, //留言板
  // xxfw, //信息服务
  userCenter, //用户中心
  // exampleRoute,
  //申请
  // apply,
  myApplicationRoute,
  //预约
  // serviceAppointment,
  myAppointmentRoute,
  // visitionService,
  // myVisitRoute,
  //查询
  //progressQuery,//进度查询
  // certificateVerification,
  // quanSZMQuery,
  myQueryRoute, //我的查询
  express,
  // onlinePay,
  // electronicLicenseRoute,
  aboutRoute, // 关于我们
  statementRoute, // 声明
  newmanGuideRoute, //新手指南
  connectRoute, // 联系我们
  callRoute, //联系我们电话页
];
Vue.use(Router);

/**
 * 增加子模块的激活菜单元数据，
 * example: { meta: { activePath: parentPath } }
 * 这个数据会在 layout/Navbar.vue 中进行导航的激活状态判断
 */
treeEach(subRoutes, (item, index, arr, parent) => {
  parent && (item.meta.activePath = parent.meta.activePath || parent.path);
});

//海南
const login = (ticketSNO, next) => {
  unifiedService
    .getAccessToken({
      ticket: ticketSNO,
      ssoLoginUrl: window.CONFIG.login + 'login',
      appKey: window.CONFIG.APP_IEBDC_KEY,
    })
    .then((res) => {
      if (res.success) {
        localDataUser.set({
          token: res.data.gmsso_cli_ec_key,
          UNIFIED_AUTHORIZATION: res.data.tokenSno,
        });

        checkTokenHandle(res.data.gmsso_cli_ec_key, next);
      }
    })
    .catch((error) => {
      if (error.message === '该账号已过期,请联系主用户调整有效期') {
        Vue.prototype.$alert(error.message, '登陆失败', {
          confirmButtonText: '确定',
          callback: () => {
            window.location.href = `${window.CONFIG.UNIFIED_LOGOUT}?backUrl=${window.CONFIG.UNIFIED_PAGE}`;
          },
        });
      } else {
        Vue.prototype.$message.error(error.message);
        next('/home-login-out');
      }
    });
};

// 路由集合
const routes = [
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('../views/change-password'),
  },
  {
    path: '/pre-sign-in',
    name: 'pre-sign-in',
    component: () => import('../views/pre-sign-in'),
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('../views/sign-in'),
  },
  {
    path: '/home-login-out',
    name: 'home-login-out',
    component: () => import('iebdc/views/new-dashboard'),
    beforeEnter: async (to, from, next) => {
      // console.log(allPermission, 'allPermission');
      // const allPermission = localDataPermission.get();
      // if (allPermission && allPermission.length > 0) {
      //   routerPermission(subRoutes, allPermission);
      // } else {
      //   await store.dispatch('getPermission');
      // }
      // 获取所有区域
      // store.dispatch('setArea', res.data);
      store.dispatch('area/setArea', JSON.parse(localStorage.getItem('finalAreaDataLocal')));
      next();
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../layout'),
    redirect: subRoutes[0].path,
    // 子模块的路由注册
    children: subRoutes,
    // beforeEnter: async (to, from, next) => {
    //   // console.log('beforeEnter');
    //   // const userData = localDataUser.get();
    //   // if (!userData || !userData.userId) {
    //   //   store.dispatch('getPermission');
    //   //   next('/home-login-out');
    //   // }
    //   // const allPermission = localDataPermission.get();
    //   // if (allPermission.length > 0) {
    //   //   routerPermission(subRoutes, allPermission);
    //   // }
    //   // //获取所有区域
    //   // await getAreaData().then((res) => {
    //   //   if (res.success) {
    //   //     store.dispatch('setArea', res.data);
    //   //   }
    //   // });
    //   console.log(localDataUser.get());
    //   console.log(localDataUser.get().areaId);

    //   if (localDataUser.get()) {
    //     console.log(localDataUser.get().areaId);
    //     //获取当前用户所属机构
    //     await getOrg({ id: '466519d314b94571a39681096de5e30f' }).then((res) => {
    //       if (res.success) {
    //         console.log('setOrg', res.data);
    //         store.dispatch('setOrg', res.data);
    //       }
    //     });
    //     next();
    //   } else {
    //     // next('/login');
    //   }
    //   // next();
    // }
  },
  {
    path: '**',
    beforeEnter: async (to, from, next) => {
      // if ((localDataPermission.get() || []).includes('IEBDC:DL:TYRZ')) {
      //   // 海南统一登陆相关
      //   if (window.location.search.indexOf('ywh0') > -1) {
      //     let ywh = window.top.location.search.substr(1).match(/(^|&)ywh0=([^&]*)(&|$)/)[2];
      //     var url = window.location.href; //获取当前页面的url
      //     //判断是否存在参数
      //     url = url.replace(/(\?|#)[^'"]*/, ''); //去除参数
      //     window.history.pushState({}, 0, url);
      //     next({ name: 'user-center', params: { ywh: ywh } });
      //   } else if (window.location.search.indexOf('ticketSNO') > -1) {
      //     //next('/home-login-out');
      //     //let ticketSNO = new URL(location.href).searchParams.get('ticketSNO');
      //     let ticketSNO = window.location.search.substr(1).match(/(^|&)ticketSNO=([^&]*)(&|$)/)[2];
      //     let url = window.location.href; //获取当前页面的url
      //     //判断是否存在参数
      //     url = url.replace(/(\?|#)[^'"]*/, ''); //去除参数
      //     window.history.pushState({}, 0, url);
      //     login(ticketSNO, next);
      //   } else if (window.location.search.indexOf('check') > -1) {
      //     let url = window.location.href; //获取当前页面的url
      //     //判断是否存在参数
      //     url = url.replace(/(\?|#)[^'"]*/, ''); //去除参数
      //     window.history.pushState({}, 0, url);
      //     next('/home-login-out');
      //   } else {
      //     window.location.href = `${window.CONFIG.UNIFIED_CHECK}?gotoUrl=${window.CONFIG.UNIFIED_PAGE}?check`;
      //   }
      // } else {
      next('/home-login-out');
      // }
    },
  },
];

const routeData = new Router({
  //mode: 'history',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
});

routeData.beforeEach(async (to, from, next) => {
  const userData = localDataUser.get();
  const allPermission = localDataPermission.get() || [];
  let pathName = [
    'home',
    'user-center',
    'wybl',
    'xxfw',
    'info-search',
    'newQuanSZM-query',
    'public-announcement',
    'guidance',
    'policy-law',
    'bswd',
  ];
  let logoutPathName = [
    'home',
    'wybl',
    'xxfw',
    'info-search',
    'newQuanSZM-query',
    'public-announcement',
    'guidance',
    'policy-law',
    'bswd',
  ];
  let topPathName = ['about', 'statement', 'newman-guide', 'connect', 'info-search', 'guidance', 'call']; // 不需要登录就可以查看的页面
  // 办事指南、政策法规、公示公告未登录也可查看。办事指南,所有配置流程全部显示，未登录不可办理，登陆后，后台设置为不展示的也不可办理
  console.log('to.path', to.path);
  console.log('to.name', to.name);
  console.log(to.path.indexOf('/home/') > -1 && !topPathName.includes(to.name));
  if (to.path.indexOf('/home/') > -1 && !topPathName.includes(to.name)) {
    console.log(userData, !userData && logoutPathName.indexOf(to.name));
    if (!userData && logoutPathName.indexOf(to.name) < 0) {
      if (allPermission && allPermission.length > 0) {
        routerPermission(subRoutes, allPermission);
      } else {
        await store.dispatch('getPermission');
      }
      if (allPermission.includes('IEBDC:DL:TYRZ')) {
        new Vue()
          .$confirm('请登录后再办理业务', '提示', {
            confirmButtonText: '立马登录',
            cancelButtonText: '暂不登录',
            type: 'warning',
            center: true,
          })
          .then(() => {
            // window.location.href = `${window.CONFIG.UNIFIED_LOGIN}?backUrl=${window.CONFIG.UNIFIED_PAGE}`;
            // window.location.href = `${crypto.decryptByDES(
            //   window.CONFIG.LOGIN_TO_ZWW.url
            // )}/xjwwrz/login/oauth2login?client_id=${
            //   window.CONFIG.LOGIN_TO_ZWW.client_id
            // }&state=a&response_type=code&scope=user&redirect_uri=${crypto.decryptByDES(
            //   window.CONFIG.LOGIN_TO_ZWW.redirect_uri
            // )}`;
            window.location.href = `${crypto.decryptByDES(
              window.CONFIG.LOGIN_TO_ZWW.url
            )}/xjwwrz/login/oauth2login?client_id=${
              window.CONFIG.LOGIN_TO_ZWW.client_id
            }&state=a&response_type=code&scope=user&redirect_uri=${crypto.decryptByDES(
              window.CONFIG.LOGIN_TO_ZWW.redirect_uri
            )}`;
          })
          .catch();
      } else {
        new Vue().$message.warning('请先登录系统');
      }
      console.log('11111===11111');
      next({ name: 'home-login-out' });
      // location.reload();
    } else if (userData && Number(userData.realNameStatus) === 0 && pathName.indexOf(to.name) < 0) {
      if (userData.status === 'SHZ' || userData.status === 'WYZ') {
        new Vue().$alert('该用户的企业审核中，请确认通过后再办理业务！', '提示', {
          confirmButtonText: '确定',
        });
      } else if (userData.status === 'SHWTG') {
        new Vue()
          .$confirm('企业审核未通过请前往用户中心修改法人信息', '提示', {
            confirmButtonText: '立即前往',
            cancelButtonText: '暂不修改',
            type: 'warning',
            center: true,
          })
          .then(() => {
            next({ name: 'user-center' });
          })
          .catch();
      } else {
        if (allPermission.includes('IEBDC:DL:TYRZ')) {
          new Vue()
            .$confirm('用户未实名认证，请前往实名认证！', '提示', {
              confirmButtonText: '立马认证',
              cancelButtonText: '暂不认证',
              type: 'warning',
              center: true,
            })
            .then(() => {
              if (userData.agentUser) {
                Vue.prototype.$slsm();
              } else if (userData.userCategory === 'NORMAL') {
                Vue.prototype.$slsm();
              } else {
                Vue.prototype.$qyrz();
              }
            })
            .catch();
        } else {
          next();
        }
      }
    } else if (
      userData &&
      pathName.indexOf(to.name) < 0 &&
      Number(userData.realNameStatus) === 1 &&
      (userData.status === 'SHZ' || userData.status === 'WYZ' || userData.status === 'SHWTG')
    ) {
      new Vue().$alert('该用户的企业审核未通过，请确认通过后再办理业务！', '提示', {
        confirmButtonText: '确定',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default routeData;
